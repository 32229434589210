
import {Link} from 'react-router-dom'
import React from 'react';
import './Footer.css'; 
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";


const Footer = () => {
    return (

<>
<div className="relative w-full">
        <img className="w-full h-auto" src={require('../../img/Wave_grey_bottom_left_shape_01.png')} alt="" />

</div>
        <div className="relative  text-black py-10 " style={{background:"#f7f7f5"}}>
            
            {/* Footer Content */}
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
                <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 gap-8">
                    {/* Footer Column 1 */}
                    <div>
                        <h4 className="font-bold mb-4">About Us</h4>
                        <ul>
                            <li className="mb-2"><Link to="/about" className="hover:text-gray-400">Our Story</Link></li>
                            <li className="mb-2"><Link to="/about#team" className="hover:text-gray-400">Team</Link></li>
                            <li className="mb-2"><Link to="/career" className="hover:text-gray-400">Careers</Link></li>
                        </ul>
                    </div>

                    {/* Footer Column 2 */}
                    <div>
                        <h4 className="font-bold mb-4">Services</h4>
                        <ul>
                            <li className="mb-2"><Link to="/service/application" className="hover:text-gray-400">Mobile Application</Link></li>
                            <li className="mb-2"><Link to="/service/website" className="hover:text-gray-400">Website Development</Link></li>
                            <li className="mb-2"><Link to="/service/microsoft" className="hover:text-gray-400">Microsoft Development</Link></li>
                            <li className="mb-2"><Link to="/service/database" className="hover:text-gray-400">Data Base Management</Link></li>
                            <li className="mb-2"><Link to="/service/testing" className="hover:text-gray-400">Testing & Validation</Link></li>
                        </ul>
                    </div>

                    {/* Footer Column 3 */}
                    <div>
                        <h4 className="font-bold mb-4">Support</h4>
                        <ul>
                            <li className="mb-2"><Link to="/contact" className="hover:text-gray-400">Contact Us</Link></li>
                            {/* <li className="mb-2"><Link to="/contact" className="hover:text-gray-400">Help Center</Link></li> */}
                        </ul>
                        <p className='font-bold text-black'>28 Harin Barin Lane,<br />
                        1st floor, Kolkata, West Bengal</p>
                        <div className="-ml-3 md:ml-0">
                          <p className="text-gray-700 font-bold">        
                          8377903762</p>
             
                
              </div>
                    </div>

                    {/* Footer Column 4 */}
                    <div>
                        <h4 className="font-bold mb-4">Follow Us</h4>
                        <div className="flex space-x-4">
                            <Link to="https://www.facebook.com/profile.php?id=61558702672770&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                            <FaFacebook style={{fontSize:"30px",color:"blue",}}/>
                            </Link>
                            <Link to="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                            <FaXTwitter style={{fontSize:"30px",color:"#1DA1F2"}}/>
                            </Link>
                            <Link to="https://www.instagram.com/treasury_technology?igsh=cmlzODE1c3Z3cjYy" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                            <FaInstagram style={{fontSize:"30px", color:"#fa7e1e"}}/>
                            </Link>
                            <Link to="https://www.linkedin.com/company/treasury-technologies/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                            <FaLinkedin style={{fontSize:"30px", color:"#0072b1"}}/>
                            </Link>
                            <Link to="https://whatsapp.com/channel/0029VaJrr0x4inoh4j4Gn33d" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                            <FaWhatsapp style={{fontSize:"30px", color:"#0cc243"}}/>
                            </Link>
                        </div>
                        <img src={require('../../img/iso.png')} alt="" className='h-[60px] mt-5'/>
                    </div>
                </div>

                {/* Footer Bottom Text */}
                <div className="text-center mt-8">
                  <Link to="https://treasurytechnologies.co.in/">
                  <p>&copy; 2019: Treasury Technologies. All rights reserved.</p>
                  </Link>
                  
                </div>
            </div>
        </div>
</>


    );
};

export default Footer;


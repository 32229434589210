
import React from 'react';
import '../style/testimonial.css';

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { Autoplay, } from 'swiper/modules';

import { FaQuoteLeft } from "react-icons/fa";
const ClientTestimonial = () => {
    return (
        <>
        
        <div className="section mt-12 shadow-lg p-6 " style={{background:"#2c2f34"}}>
    <div className="container mx-auto">
        <h3 className="heading-h3 text-center border-b-2 border-gray-300 pb-2 mb-6 text-white">CLIENT TESTIMONIAL</h3>
        <div className="client-testimonial-section">
            <Swiper
                watchSlidesProgress={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                loop={true}
                slidesPerView={1}
                breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="client-testimonial-box bg-[#D4E6F1] p-4 rounded-md shadow-md">
                        <div className='quote-icon text-2xl text-gray-600 mb-2'>
                            <FaQuoteLeft />
                        </div>
                        <p className="para text-gray-700">
                        Working with Treasury Technologies was a game-changer for our business. Their expertise in e-commerce website  helped us achieve business growth, and their dedication to quality is unmatched."                        </p>
                        <h4 className="heading-h4 font-semibold mt-4">Jay Prakash Rathor,</h4>
                        <p className="para text-gray-600"><b>Bengaluru, Karnataka</b></p>
                        <p>Harbally food</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="client-testimonial-box bg-[#D4E6F1] p-4 rounded-md shadow-md">
                        <div className='quote-icon text-2xl text-gray-600 mb-2'>
                            <FaQuoteLeft />
                        </div>
                        <p className="para text-gray-700">
                        Treasury Technologies delivered exactly what we needed, on time and within budget. Their team's professionalism and technical prowess made the entire process smooth and successful."                        </p>
                        <h4 className="heading-h4 font-semibold mt-4">Santosh Gahlod</h4>
                        <p className="para text-gray-600"><b>Jaipur, Rajasthan</b></p>
                        <p>R.N. Agro</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="client-testimonial-box bg-[#D4E6F1] p-4 rounded-md shadow-md">
                        <div className='quote-icon text-2xl text-gray-600 mb-2'>
                            <FaQuoteLeft />
                        </div>
                        <p className="para text-gray-700">
                        We've partnered with TT for multiple projects, and each time, they've exceeded our expectations. Their innovative approach and attention to detail set them apart."                        </p>
                        <h4 className="heading-h4 font-semibold mt-4">Jignesh Bhai</h4>
                        <p className="para text-gray-600"><b>Ahmedabad, Gujarat</b></p>
                        <p>Varanyam Energy Solution</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="client-testimonial-box bg-[#D4E6F1] p-4 rounded-md shadow-md">
                        <div className='quote-icon text-2xl text-gray-600 mb-2'>
                            <FaQuoteLeft />
                        </div>
                        <p className="para text-gray-700">
                        Choosing TT for our SharePoint Development was one of the best decisions we made. Their commitment to understanding our needs and delivering tailored solutions was impressive."                        </p>
                        <h4 className="heading-h4 font-semibold mt-4">Suhana Saikh</h4>
                        <p className="para text-gray-600"><b>Hyderabad, Telangana</b></p>
                        <p>SunShine Tour Packages</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="client-testimonial-box bg-[#D4E6F1] p-4 rounded-md shadow-md">
                        <div className='quote-icon text-2xl text-gray-600 mb-2'>
                            <FaQuoteLeft />
                        </div>
                        <p className="para text-gray-700">
                        The impact of TT's solutions on our business has been remarkable.Their team's expertise and support have been invaluable in driving our growth and success."                        </p>
                        <h4 className="heading-h4 font-semibold mt-4">Prateek Pathak</h4>
                        <p className="para text-gray-600"><b>Baddi, Himanchal</b></p>
                        <p>Green Medicure</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</div>


                   </>
    )
}

export default ClientTestimonial

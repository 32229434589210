import React from 'react'
import { useEffect } from 'react';
import { useLocation,Link } from 'react-router-dom';
import '../pages/services/service.css'
import { GiRadialBalance } from "react-icons/gi";
import { LuHeartHandshake } from "react-icons/lu";
import { FaPeopleRoof } from "react-icons/fa6";
import { BsMicrosoftTeams } from "react-icons/bs";
import { TiSortAlphabeticallyOutline } from "react-icons/ti";
import { FaCheckDouble } from "react-icons/fa";
import { BsBracesAsterisk } from "react-icons/bs";
import { MdOutlineGroupAdd } from "react-icons/md";
export default function About() {
  useEffect(() => {
    const handleScroll = () => {
      const textElement = document.getElementById('about-text');
      const backgroundElement = document.getElementById('about-background');
      const scrollY = window.scrollY;
      textElement.style.transform = `translateY(-${scrollY * 1}px)`;
      backgroundElement.style.backgroundPositionY = `${scrollY * 0.25}px`;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


// location and id
  const location = useLocation();
  useEffect(() => {
    if (location.hash === '#team') {
      const teamSection = document.getElementById('team');
      if (teamSection) {
        teamSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      {/* first hero */}
      <div className="relative overflow-hidden h-[300px] md:h-[400px] lg:h-[800px] ">
        <div id="about-background" className="absolute inset-0 lg:bg-cover md:bg-[length:800px_400px] bg-[length:400px_250px] bg-fixed"></div>
        <div id="about-text" className="relative z-10 lg:top-64 md:top-64 top-20 text-white lg:ml-[30%] md:ml-24 ml-5">
          <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold mb-4">
            About</h2>
          <p>We are a young and creative company <br /> and we offer you fresh business ideas.</p>

        </div>
        <div className="absolute animated-element bottom-10 -left-10 z-10">
          <img className="w-32 md:w-52 lg:w-96" src={require('../../img/floating_image_05.png')} alt="" />
        </div>
        <div className="absolute animated-element bottom-10 -right-0 ">
          <img className="w-20 md:w-32 lg:w-52" src={require('../../img/floating_image_08.png')} alt="" />
        </div>
        <div className="absolute -bottom-1">
          <img src={require('../../img/bottom_wave_02_gray.png')} alt="" />
        </div>
      </div>




      {/* second hero */}
      <div className=" mx-auto p-4 bg-white">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-4">About us</h1>
        <h2 className="text-xl md:text-2xl text-center mb-8">How did we kick start?</h2>
        <p className="text-base md:text-lg mb-6">
        At Treasury Technologies, our journey began from a shared passion for Information Technology industry, fuelled by a desire to redefine how businesses sales grow. Founded in 2019, we set out to create innovative solutions that streamline operations or deliver exceptional digital experiences. With a commitment to core values such as innovation, reliability, we quickly gained recognition for our ability to solve complex technological issues.
Since then, our dedication to client satisfaction has driven our growth, enabling us to expand our service offerings globally and partner with industry leaders. Today, we stand proud as a leading Tech solutions provider.
Explore our journey and discover how we can empower your business to thrive in an ever-evolving digital landscape.
This text provides a more detailed narrative of your company's beginnings, core values, milestones, and growth trajectory, offering visitors a deeper insight into your company's history and evolution. Adjust the specifics to accurately reflect your company's unique story and achievements

        </p>
        <p className="text-base md:text-lg">
          Teamed up with technical oracles, Treasury Technologies renders a thorough solution that fits
          latest corporate stipulations, from the day of inception. We stretched our services in all
          countries worldwide, partnering with European and American firms. We have accomplished huge
          success by practicing Work hard, Play hard formula.
        </p>
      </div>
      <div class=" mx-auto px-4 py-8 bg-white">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div class="text-center">
            <GiRadialBalance style={{ fontSize: "100px", color: "#577B8D", marginLeft: "40%" }} />
            <h3 class="text-lg font-semibold">Customer centric</h3>
            <p class="text-sm">Prioritize customer first understanding user’s pain factors</p>
          </div>
          <div class="text-center">
            <LuHeartHandshake style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>      <h3 class="text-lg font-semibold">Trust</h3>
            <p class="text-sm">Build trust to deliver world class products</p>
          </div>
          <div class="text-center">
            <FaPeopleRoof style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>      <h3 class="text-lg font-semibold">Partnership</h3>
            <p class="text-sm">Reputed and Reliable partners deeply rooted for years</p>
          </div>
          <div class="text-center">
            <TiSortAlphabeticallyOutline style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>      <h3 class="text-lg font-semibold">Precise</h3>
            <p class="text-sm">Clear call for every action implemented</p>
          </div>
          <div class="text-center">
            <BsMicrosoftTeams style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>
            <h3 class="text-lg font-semibold">Teamwork</h3>
          </div>
          <div class="text-center">
            <FaCheckDouble style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>      <h3 class="text-lg font-semibold">Quality</h3>
          </div>
          <div class="text-center">
            <BsBracesAsterisk style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>      <h3 class="text-lg font-semibold">Risk</h3>
          </div>
          <div class="text-center">
            <MdOutlineGroupAdd style={{fontSize:"100px", color:"#577B8D", marginLeft:"40%" }}/>      <h3 class="text-lg font-semibold">Communication</h3>
          </div>
        </div>
      </div>


      {/* core team */}
      <div className="bg-white dark:bg-zinc-900 py-12" id='team'>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-extrabold gradient-text text-transparent sm:text-4xl">Our Core Team</h2>
          <p className="mt-4 text-lg leading-6 text-zinc-600 dark:text-zinc-300">
          "Great things in business are never done by one person; they're done by a team of people." - Steve Jobs"          </p>
        </div>
        <div className="mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-lg overflow-hidden">
              <img className="w-full h-[350px] object-cover" src={require('../../img/shahbaz.jpeg')} alt="Team Member 1" />
              <div className="p-4">
                <h3 className="text-lg font-medium text-zinc-900 dark:text-white">Shahbaz Alam</h3>
                <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-400">MD </p>
                <p className="mt-1 text-sm text-gray-200 lg:text-black">"Driven by a passion for innovation and excellence, leads Treasury Technologies with a vision to redefine technology. His deep tech expertise fuels our commitment to delivering cutting-edge solutions that empower global businesses" </p>
              </div>
            </div>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-lg overflow-hidden">
              <img className="w-full h-[350px] object-cover" src={require('../../img/shailendra.jpeg')} alt="Team Member 2" />
              <div className="p-4">
                <h3 className="text-lg font-medium text-zinc-900 dark:text-white">Shailendra Kumar</h3>
                <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-400">PMO</p>
                <p className="mt-1 text-sm text-gray-200 lg:text-black">"As Treasury Technologies', Shailendra drives innovation with a deep tech passion and proven expertise. His leadership ensures we lead in tech advancements, delivering scalable solutions for our global clients." </p>

              </div>
            </div>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-lg overflow-hidden">
              <img className="w-full h-[350px] object-cover" src={require('../../img/anuj.jpeg')} alt="Team Member 3" />
              <div className="p-4">
                <h3 className="text-lg font-medium text-zinc-900 dark:text-white">Anuj Dheeman</h3>
                <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-400">B.D.</p>
                <p className="mt-1 text-sm text-gray-200 lg:text-black">"Anuj leads our dynamic sales team with extensive experience in Tech sales. His strategic vision and client-centric approach ensure tailored solutions that exceed expectations." </p>

              </div>
            </div>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-lg overflow-hidden">
              <img className="w-full h-[350px] object-cover" src={require('../../img/navneet.jpeg')} alt="Team Member 4" />
              <div className="p-4">
                <h3 className="text-lg font-medium text-zinc-900 dark:text-white">Navneet Malik</h3>
                <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-400">Head of Customer Success</p>
                <p className="mt-1 text-sm text-gray-200 lg:text-black">"I lead our customer success team with a focus on ensuring client satisfaction and fostering long-term partnerships. My expertise and proactive approach drive exceptional service delivery at Treasury Technologies." </p>

              </div>
            </div>
            <Link to="/career">
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-lg overflow-hidden">
              <img className="w-full h-[350px]" src={require('../../img/you-could-be-next.jpg')} alt="Team Member 5" />
              <div className="p-4">
                <h3 className="text-lg font-medium text-zinc-900 dark:text-white">You</h3>                
              </div>
            </div>
            </Link>
          
          </div>
        </div>
      </div>

      {/* how are we different */}
      <div className=" mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-center mb-8 text-yellow-50">How are we different?</h2>
        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white  p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <img src={require("../../img/klipartz.com (1).png")} alt="icon" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Innovative Solutions</h3>
            <p className="text-zinc-600 dark:text-zinc-400">
            “Emphasizing our dedication to pioneering technologies, we specialize in creative problem-solving that drives innovation and achieves transformative results.”
            </p>
          </div>
          <div className="bg-white  p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4 lg:h-[300px]">
            <img src={require("../../img/klipartz.com (2).png")} alt="icon" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Exceptional Support</h3>
            <p className="text-zinc-600 dark:text-zinc-400">
            “Focused on delivering exceptional support, we prioritize unparalleled customer service and steadfast, reliable assistance, ensuring your needs are met with dedication and expertise."
            </p>
          </div>
          <div className="bg-white  p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4 lg:h-[300px]">
            <img src={require("../../img/klipartz.com.png")} alt="icon" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Empowering Partnerships</h3>
            <p className="text-zinc-600 dark:text-zinc-400">
            "Through empowering partnerships, our solutions are tailored to propel businesses towards achieving their goals, fostering success with innovation and strategic collaboration."
            </p>
          </div>
        </div>
      </div>

    </>
  )
}

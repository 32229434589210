import React from 'react'
import '../style/Hero.css'
import { ReactComponent as MySvg } from '../../img/01.svg';
// import { ReactComponent as MySvg2 } from '../../img/geo_round_blob_medium.svg';


export default function HeroSecond() {
  return (
    <>
    <div className="relative w-full">
      <img className="w-full h-auto" src={require('../../img/New-Microsoft-Word-Document.png')} alt="" />
      <div className="absolute top-1/3 left-1/3 text-center p-4 sm:p-6 md:p-8 lg:p-10">
  <h3 className="gradient-text text-transparent text-2xl  md:text-5xl lg:text-6xl font-extrabold">
  A futuristic digital interface <br /> showcasing technology and innovation
  </h3>

</div>
<div className="absolute top-5 left-2.5 sm:top-8 sm:left-4 md:top-12 md:left-6 lg:top-16 lg:left-8">
  <MySvg id='mySvg'/>
</div>
<div className="absolute">
  {/* <MySvg2 id='mySvg2'/> */}
  <img src={require('../../img/image_processing20210908-6085-9h03qr.gif')} alt="" id='mySvg2'/>
</div>
<div className="absolute">
  {/* <MySvg2 id='mySvg2'/> */}
  <img src={require('../../img/—Pngtree—2.5d technology internet creative design_4028904.png')} alt="" id='mySvg3'/>
</div>
<div className="absolute">
  {/* <MySvg2 id='mySvg2'/> */}
  <img src={require('../../img/—Pngtree—information technology cloud computing computer_6406341.png')} alt="" id='mySvg4'/>
</div>
    </div>

    </>

  )
}

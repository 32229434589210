import React, { useState, useEffect } from 'react';
import Preloader from './pre_Loader/Preloader.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './web/pages/Home.jsx'
import Navbar from './web/header/Navbar.jsx'
import Footer from './web/footer/Footer.jsx'
import About from './web/pages/About.jsx'
import Contact from './web/pages/Contact.jsx'
import Service from './web/pages/services/Service.jsx'
import Website from './web/pages/services/Website.jsx'
import Application from './web/pages/services/Application.jsx'
import Microsoft from './web/pages/services/Microsoft.jsx'
import Database from './web/pages/services/Database.jsx'
import Testing from './web/pages/services/Testing.jsx'
import Vision from './web/pages/Vision.jsx'
import Career from './web/pages/Career.jsx'
import ScrollToTopButton from './web/component/ScrollToTopButton.jsx';

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Set the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>

<div className="App">
      {loading ? <Preloader /> : (
        <div>

<BrowserRouter>
      <Navbar/>
      {/* <Whatsapp/> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/career" element={<Career />} />





          <Route path="/service" element={<Service />} />
          <Route path="/service/website" element={<Website />} />
          <Route path="/service/application" element={<Application />} />
          <Route path="/service/microsoft" element={<Microsoft />} />
          <Route path="/service/database" element={<Database />} />
          <Route path="/service/testing" element={<Testing />} />

        </Routes>
        <ScrollToTopButton/>
        <Footer/>
      </BrowserRouter>





        </div>
      )}
    </div>
        </>
  )
}

import React, { useState } from 'react';
import '../style/Home.css'
import HeroFirst from '../component/HeroFirst'
import HeroSecond from '../component/HeroSecond'
import HeroThrid from '../component/HeroThrid.jsx'
import Technologies from '../component/Technologies.jsx'
import ScrollTrigger from 'react-scroll-trigger'
import CountUp from 'react-countup'
import { FaRegCalendarDays } from "react-icons/fa6";
import { BsGraphUpArrow } from "react-icons/bs"
import { MdProductionQuantityLimits } from "react-icons/md"
import { FaCity } from "react-icons/fa"
import ClientTestimonial from '../component/Testimonial.jsx';
import { FaAward } from "react-icons/fa";
import {Link} from 'react-router-dom'
export default function Home() {
  
  const [counterState, setCounterState] = useState(false)
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     const bgImage = document.getElementById('hero-back');


  //     if (bgImage) {
  //       bgImage.style.transform = `scale(${1 + scrollY * 0.0002})`;
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <>
      {/* <div className="animated-element">
      I'm moving up and down!
    </div> */}
      <HeroFirst />


      <div
        className=" relative flex flex-col md:flex-row items-center justify-between p-8  dark:bg-zinc-900"
        style={{ backgroundColor: '#e6e6e6' }}>
        <div className="absolute animated-element bottom-10 right-10">
          <img className="w-16 md:w-32 lg:w-48" src={require('../../img/floating_image_08.png')} alt="" />
        </div>
        {/* <div className="absolute animated-element bottom-10 -right-0">
          <img src={require('../../img/floating_image_01.png')} alt="" />
        </div> */}
        <div class="md:w-1/2 mb-8 md:mb-0" style={{ textAlign: "center" }}>

        <div className='textbg'>
          <h3 className='text'>
          Effortless Integration
          </h3>
        </div>
         
          <p class="text-black dark:text-black mb-4">
          “Our IT solutions seamlessly integrate with your existing systems, ensuring smooth transitions and enhanced productivity. Whether it's a new website, custom mobile app, or advanced Microsoft solutions, we deliver tailored, scalable, and secure solutions that fit perfectly into your workflow.”
          </p>
          {/* <p class="text-zinc-500 dark:text-zinc-400 mb-6">
            "Start with the customer and work backward." - Steve Jobs
          </p> */}
          <Link to='/service'>           <button class="mt-6 bg-black text-white py-2 px-4 rounded-full">Learn More</button>
          </Link>
        </div>
        <div class="md:w-1/2 flex justify-center">
          <img
            src={require('../../img/s_text_image.png')} alt="Integration Illustration" class="w-full h-auto max-w-sm"
          />
        </div>
      </div>

      <HeroSecond />

      <div class="flex flex-col md:flex-row items-center  p-6 md:p-12" style={{ marginTop: "0px", backgroundColor: '#e6e6e6' }}>
        <div class="w-full md:w-1/2 flex justify-center md:justify-start">
          <img src={require('../../img/s_image_text.png')} alt="Illustration" class="w-3/4 md:w-full" />
        </div>
        <div class="w-full md:w-1/2 mt-6 md:mt-0 md:ml-6 text-center md:text-left">
        <div className='textbg'>
          <h3 className='text'>
          High-Tech Environment
          </h3>
        </div>
          <p class="mt-4 text-zinc-600">
          Experience the forefront of technology with our innovative solutions. Embrace the future of business transformation through our expertise in website development, mobile apps, and cutting-edge Microsoft solutions. Together, we’ll propel your business into the digital age, ensuring you stay ahead of the curve and thrive in tomorrow’s world.
          </p>
          {/* <p class="mt-4 text-zinc-600 italic">
            "Start with the customer and work backward." - Steve Jobs
          </p> */}
          <Link to='/service'>           <button class="mt-6 bg-black text-white py-2 px-4 rounded-full">Learn More</button>
          </Link>
        </div>

      </div>

      <HeroThrid />
      <Technologies />

      {/* <div className="cover relative">
  <img src={require('../../img/mountain.png')} alt="" id='hero-back' className="w-full h-auto"/>
  <div className="content absolute top-6 left-1/3 md:top-1/3 md:left-1/3 lg:top-[100px] lg:left-1/3">
    <h1 className='text-2xl md:text-4xl lg:text-6xl gradient-text text-transparent'>Our Mile-Stone</h1>
  </div> */}
(
    <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
      <div className='lg:mb-[100px]   lg:text-4xl text-center mt-[50px] mb-[30px] text-2xl gradient-text text-transparent'>Our Milestone</div>
      <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-2 lg:grid-cols-5">
        <div className="text-white ">
          <h2>{counterState && <CountUp start={0} end={5} duration={2.75} />} +</h2>
          <p><FaRegCalendarDays size={40} color="#FF6500" className='lg:ml-[100px]  mt-2  ml-[153px]'/>
          <br />
          Years of Experiences
          </p>
            
           </div>
        <div className="text-white">
          <h2>{counterState && <CountUp start={0} end={284} duration={2.75} />}+</h2>
          <p >
            <MdProductionQuantityLimits size={40} color="#FF6500" className='lg:ml-[100px] mt-2 ml-[153px]' />
            <br />
            Projects Installed
          </p>
        </div>
        <div className="text-white">
          <h2>{counterState && <CountUp start={0} end={8} duration={2.75} />} +</h2>
          <p>
            <FaAward size={40} color="#FF6500" className='lg:ml-[100px] mt-2 ml-[153px]' />
            <br />
            Industry Awards
          </p>
        </div>
        <div className="text-white">
          <h2>{counterState && <CountUp start={0} end={95} duration={2.75} />} %</h2>
          <p>
            <BsGraphUpArrow size={40} color="#FF6500" className='lg:ml-[100px] mt-2 ml-[153px]' />
            <br />
            Satisfied Client rate
          </p>
        </div>
        <div className="text-white">
          <h2>{counterState && <CountUp start={0} end={32} duration={2.75} />} +</h2>
          <p>
            <FaCity size={40} color="#FF6500" className='lg:ml-[100px] mt-2 ml-[153px]' />
            <br />
            Cities Covered in India
          </p>
        </div>
      </div>
    </ScrollTrigger>

      <ClientTestimonial/>
    </>
  )
}


import React, { useState, useEffect } from 'react';
import { IoHome } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { FcServices } from "react-icons/fc";
import { FaEarthAmericas } from "react-icons/fa6";
import { MdContacts } from "react-icons/md";
import { FaMobileRetro } from "react-icons/fa6";
import { FaLaptopCode } from "react-icons/fa";
import { TiVendorMicrosoft } from "react-icons/ti";
import { LuDatabaseBackup } from "react-icons/lu";
import { SiPytest } from "react-icons/si";
import { TbDeviceVisionPro } from "react-icons/tb";
import { GiGroundSprout } from "react-icons/gi";
import { IoMdArrowDropdown } from "react-icons/io";
import ScrollToTop from '../../ScrollToTop';

const Navbar = () => {

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // State variables
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Handle scrolling to change background color
  const handleScroll = () => {
    if (window.scrollY > 220) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    setMenuOpen(false);
    setIsOpen(false);
  };

  // Close menu when the location changes
  const location = useLocation();
  useEffect(() => {
    setMenuOpen(false);
    setIsOpen(false);
  }, [location]);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <ScrollToTop />

      <nav className={`fixed w-full z-50 transition-colors duration-300 ${scrolled ? 'bg-gray-900' : 'lg:bg-transparent bg-gray-900'}`}>
        <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`}>
          <div className="flex items-center justify-between h-16">
            <div className="hidden md:flex space-x-8">
              <Link onClick={handleLinkClick} to="/" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex group">
                <IoHome style={{ fontSize: "25px", color: "ffa000", marginRight: '6px' }} />Home
              </Link>
              <Link onClick={handleLinkClick} to="/about" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex group">
                <FaEarthAmericas style={{ fontSize: "25px", color: "ffa000", marginRight: '6px' }} />About
              </Link>
              <div className="relative group">
               <Link   onClick={handleLinkClick}  to="/service" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex">
                 <FcServices style={{ fontSize: "25px", color: "ffa000", marginRight: '6px' }} />Services
               </Link>
               <div className="absolute left-0 hidden mt-0 w-48 bg-gray-800 shadow-lg group-hover:block">
                 <Link   onClick={handleLinkClick}  to="/service/application" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex" ><FaMobileRetro style={{ marginTop: "4px", marginRight: '6px' }} />Mobile Application</Link>
                 <Link   onClick={handleLinkClick}  to="/service/website" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex"><FaLaptopCode style={{ marginTop: "4px", marginRight: '6px' }} />Web Development</Link>
                 <Link   onClick={handleLinkClick}  to="/service/microsoft" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex"><TiVendorMicrosoft style={{ marginTop: "4px", marginRight: '6px' }} />Microsoft Development</Link>
                 <Link   onClick={handleLinkClick}  to="/service/database" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex"><LuDatabaseBackup style={{ marginTop: "4px", marginRight: '6px' }} />DataBase Management</Link>
                 <Link   onClick={handleLinkClick}  to="/service/testing" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex"><SiPytest style={{ marginTop: "4px", marginRight: '6px' }} />Testing And Validation</Link>
               </div>
             </div>
            </div>

            <div className="flex items-center">
              <Link onClick={handleLinkClick} to="/" className="text-xl font-bold gradient-text text-transparent "><img src={require('../../img/logo.png')} alt="" className='h-8 w-16 ml-16' />Treasury Technologies</Link>
            </div>

            <div className="hidden md:flex space-x-8">
              <Link onClick={handleLinkClick} to="/vision" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex">
                <TbDeviceVisionPro style={{ fontSize: "25px", color: "ffa000", marginRight: '6px' }} />Vision
              </Link>
              <Link onClick={handleLinkClick} to="/career" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex">
                <GiGroundSprout style={{ fontSize: "25px", color: "ffa000", marginRight: '6px' }} />Career
              </Link>
              <Link onClick={handleLinkClick} to="/contact" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex">
                <MdContacts style={{ fontSize: "25px", color: "ffa000", marginRight: '6px' }} />Contact
              </Link>
            </div>
            <div className="flex lg:hidden ">
              <button onClick={toggleMenu} type="button" className="text-gray-500 hover:text-red focus:outline-none">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  {isOpen ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link onClick={handleLinkClick} to="/" className="text-white hover:text-orange-500  px-3 py-2 rounded-md text-base font-medium flex"><IoHome style={{ fontSize: "25px", color: "ffa000", marginRight: '3px' }} /> Home</Link>
            <Link onClick={handleLinkClick} to="/about" className="text-white hover:text-orange-500 flex px-3 py-2 rounded-md text-base font-medium"><FaEarthAmericas style={{ fontSize: "25px", color: "ffa000", marginRight: '3px' }} />About</Link>
            <div className="relative group">
<div className='flex'>
              <Link onClick={handleLinkClick} to="/service" className="text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium flex">
                <FcServices style={{ fontSize: "25px", color: "ffa000", marginRight: '3px' }} />Services
              </Link>
              <button onClick={toggleDropdown} className="text-white hover:text-orange-500 -px-1 py-[6px] rounded-md text-sm font-medium flex">       
                <IoMdArrowDropdown style={{ fontSize: "25px", color: "#ffffff", marginRight: '6px' }} />
              </button>
              </div>
              {menuOpen && (
                <div className="absolute left-0 mt-0 w-48 bg-gray-800 shadow-lg">
                  <Link onClick={handleLinkClick} to="/service/application" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex">
                    <FaMobileRetro style={{ marginTop: "4px", marginRight: '6px' }} />Mobile Application
                  </Link>
                  <Link onClick={handleLinkClick} to="/service/website" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex">
                    <FaLaptopCode style={{ marginTop: "4px", marginRight: '6px' }} />Web Development
                  </Link>
                  <Link onClick={handleLinkClick} to="/service/microsoft" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex">
                    <TiVendorMicrosoft style={{ marginTop: "4px", marginRight: '6px' }} />Microsoft Development
                  </Link>
                  <Link onClick={handleLinkClick} to="/service/database" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex">
                    <LuDatabaseBackup style={{ marginTop: "4px", marginRight: '6px' }} />DataBase Management
                  </Link>
                  <Link onClick={handleLinkClick} to="/service/testing" className="px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 flex">
                    <SiPytest style={{ marginTop: "4px", marginRight: '6px' }} />Testing And Validation
                  </Link>
                </div>
              )}
            </div>

            <Link onClick={handleLinkClick} to="/vision" className="text-white hover:text-orange-500 flex px-3 py-2 rounded-md text-base font-medium"><TbDeviceVisionPro style={{ fontSize: "25px", color: "ffa000", marginRight: '3px' }} />Vision</Link>
            <Link onClick={handleLinkClick} to="/career" className="text-white hover:text-orange-500 flex px-3 py-2 rounded-md text-base font-medium"><GiGroundSprout style={{ fontSize: "25px", color: "ffa000", marginRight: '3px' }} />Career</Link>
            <Link onClick={handleLinkClick} to="/contact" className="text-white hover:text-orange-500 flex px-3 py-2 rounded-md text-base font-medium"><MdContacts style={{ fontSize: "25px", color: "ffa000", marginRight: '3px' }} />Contact</Link>
          </div>
        </div>
      </nav>

    </>
  );
};

export default Navbar;

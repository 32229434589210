import React, { useState, useEffect } from 'react';
export default function HeroFirst() {

  const [loadedText, setLoadedText] = useState('');
  const text = `Revolutionize Your Business Operations`;

  useEffect(() => {
    const textArray = text.split('');
    let timeouts = [];

    textArray.forEach((char, index) => {
      const timeout = setTimeout(() => {
        setLoadedText(prevText => prevText + char);
      }, 100 * index);
      timeouts.push(timeout);
    });

    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, [text]);


  return (
<>

<div className="relative pt-[70px] lg:pt-0">
             <video
                className="top-0 left-0 w-full h-full object-cover -z-10"
                src={require('../../img/video.mp4')}
                autoPlay
                loop               
                muted
            ></video>

            

<div className="absolute top-1/3 right-1/3 text-center">
        <h3 className="text-white text-4xl lg:text-6xl font-extrabold text-center">
        {loadedText}
        </h3>
      </div>
      <div className="absolute animated-element bottom-10 -left-10">
       <img className="w-32 md:w-52 lg:w-96" src={require('../../img/floating_image_05.png')} alt="" />
      </div>
      <div className="absolute animated-element bottom-10 -right-0 ">
       <img className="w-20 md:w-32 lg:w-52" src={require('../../img/floating_image_08.png')} alt="" />
      </div>
      <div className="absolute  w-full  bottom-0 opacity-50">
       <img className="w-full h-auto" src={require('../../img/22.png')} alt="" />
      </div>
    </div>
    <div className="w-full">
  <img className="w-full h-auto" src={require('../../img/22 (1).png')} alt="" />
</div>

    </>
  );
};


